<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="default-dt">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="title129">
                <h2>Refund and Return Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="life-gambo">
        <div class="container">
          <div class="row">
            <div v-html="settings.terms"></div>

            <p>
              <br />
              <strong>Returnable Products</strong><br />
              Returns must be initiated within 7 days from the date of delivery
              of products, subject to the other terms and conditions of this
              Cancellation, Return and Refund Policy. Do read all sections
              carefully to understand the conditions and cases under which
              returns will be accepted and check the Products display page for
              Product specific return information. (subject to Para 4.7, please
              keep the item in its original condition, with outer box or case,
              user manual, warranty cards, and other accompaniments in
              manufacturer packaging for a successful return pick-up)
            </p>

            <p>
              <strong> Non-Returnable Products</strong><br />
              Notwithstanding anything to the contrary, Users cannot return
              certain Products which are specified as ‘non-returnable’ on the
              relevant Product pages of the Platforms, and (b) Such Products may
              be returned only in case of defects or damages in the Products,
              incorrect products shipped to the User, missing components and
              empty sealed packages, and not for any other reason.
            </p>

            <p>
              <strong>Return of Non-Returnable Products</strong><br />
              The User can only return Non-returnable Products if there are
              defects and deficiencies in the Product (attributable to, and
              accepted by bijleebox after due verification at its sole
              discretion) or if such Non-returnable Products are delivered late.
              The User shall contact us by sending an email to
              <a href="contact"> contactus@bijleebox.com</a> to initiate a
              request for returning such Non-returnable Products not later than
              7 (seven) days from the date of delivery of Product.
            </p>

            <p>
              <strong> Return/Refund Process</strong><br />
              Subject to the terms of this Cancellation, Return and Refund
              Policy, bijleebox offers Users an easy return/refund policy.
              bijleebox also accepts partial returns wherein User can raise a
              return request for one or all Products except in case of Bundled
              Packages (as defined hereinafter). The process for initiating the
              process for raising a return/refund/exchange request is:
            </p>
            <ul>
              <li>
                Login with Your credentials and visit the
                <a href="login">‘My Account’ </a>section.
              </li>
              <!-- <li>
                Select the order You want to return and click on
                “Return/Exchange” and follow the instructions on this page to
                obtain a Return ID.
              </li> -->
              <li>
                If the User had used the pay on delivery mode of payment, they
                will be prompted to enter the customer’s bank and account
                details.
              </li>
              <li>
                The Products will be picked up within the timelines intimated by
                bijleebox.
              </li>
              <li>
                bijleebox will initiate the refund process only if the Products
                are received in accordance with the Cancellation, Return and
                Refund Policy.
              </li>
            </ul>

            <p>
              <strong>Refund Process</strong><br />
              While raising a request for return on the Platforms, the User
              shall have the option to seek a refund of the monies paid by
              him/her towards the purchase of the Product. The User may be
              required to produce a copy of the original invoice at the time of
              placing a request for return of Products.
            </p>

            <p>
              <strong>Bundled Packages</strong><br />
              If a User has purchased Products which form a part of a package of
              other Products, or if the Product forms a part of a promotional
              package, the User shall be required to return all the Products
              that form a part of the Bundled Package for bijleebox to process
              refunds.
            </p>

            <p>
              <strong> Conditions for Rejecting Returns</strong><br />
              Return of or refund for purchased Products shall not be
              entertained by bijleebox if:
            </p>
            <ul>
              <li>The Product has been used.</li>
              <li>
                The price tags, brand tags, box, original packaging material,
                and accompanying accessories have been damaged or discarded by
                the User.
              </li>
              <li>
                The serial number / bar code of the Product does not match
                bijleebox records.
              </li>
              <li>
                Accessories delivered with the Product are not returned in an
                undamaged condition.
              </li>
            </ul>

            <p>
              <strong>Grievance Redressal</strong><br />
              Any grievances relating to the Cancellation, Return and Refund
              Policy may be directed by You to the grievance officer of
              bijleebox who can be contacted at
              <a href="contact">contactus@bijleebox.com.</a>
            </p>

            <p>
              <strong>Note:</strong><br />
              Please ensure that the Product(s) being returned comply with the
              conditions set out under this Cancellation, Return and Refund
              Policy and are, among other conditions, unused with no damage and
              stains, with price label intact, all packaging material undamaged
              and unused.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  data() {
    return {
      settings: {},
    };
  },
  async mounted() {
    let settings = await axios.get("settings");
    this.settings = settings.data.data[0];
  },
};
</script>
<style scoped>
p {
  font-size: 16px;
  text-align: start;
}
ul {
  font-size: 16px;
  list-style-type: disc;
  padding-left: 20px;
}

li {
  margin-bottom: 10px;
  line-height: 1.6;
}
.life-gambo {
  padding: 5px 0;
}
.col-lg-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 39px;
}
.title129 h2 {
  font-size: 49px;
  font-weight: 700;
  margin-top: 16px !important;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
