<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="default-dt">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="title129">
                <h2>Terms and Conditions</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="life-gambo">
        <div class="container">
          <div class="row">
            <div v-html="settings.terms"></div>

            <p>
              <strong>Acceptance of Terms of Use</strong><br />
              Please read these Terms of Use carefully. These Terms of Use
              govern your access and use of this Site. By accessing or using
              this Site you agree to be bound by these Terms of Use and to any
              additional guidelines, restrictions, or rules that may be posted
              in connection with specific sections or services of this Site. All
              such additional posted guidelines, restrictions, or rules are
              hereby incorporated by reference into these Terms of Use.
              <a href="https://bijleebox.com/">bijleebox.com</a> reserves the
              right to make changes to this Site and to these Terms of Use at
              any time without prior notice. You should review these Terms of
              Use each time you access this Site.
            </p>

            <p>
              You also agree that we may provide all legal communications and
              notices to you electronically by posting them on our website or,
              at our election, by sending an e-mail to the e-mail address you
              provided to us when you registered at our website. You may
              withdraw your consent to receive communications from us
              electronically by contacting customer care. However, if you
              withdraw your consent to receive our communications
              electronically, you must discontinue your use of your account.
            </p>

            <p>
              <strong>Copyrighted Materials for Limited Use</strong><br />
              This Site contains graphics, photographs, images, document
              layouts, text, fonts, software tools, and other information
              (referred to here in as “Content”). This Site and all Content are
              the copyrighted property of
              <a href="https://bijleebox.com/">bijleebox.com</a> and its
              subsidiaries (referred to herein as “<a
                href="https://bijleebox.com/"
                >bijleebox.com</a
              >”) or the copyrighted property of parties from whom
              <a href="https://bijleebox.com/">bijleebox.com</a> has licensed
              such property. All rights in this Site and its Content are
              reserved worldwide.
            </p>

            <p>
              <strong>Use of Site</strong><br />
              You are granted permission to access and use this Site and its
              Content for the sole purpose of evaluating, and ordering products
              solely through <a href="https://bijleebox.com/">bijleebox.com</a>.
              No other download, retention, use, publication, or distribution of
              any portion of the Content is authorized or permitted. Obtaining
              Products from
              <a href="https://bijleebox.com/">bijleebox.com</a> does not
              entitle you to use any portion of Content apart from the finished
              Products as they are supplied by
              <a href="https://bijleebox.com/">bijleebox.com</a>.
            </p>

            <p>
              <strong>Indemnification</strong><br />
              You agree that you shall indemnify and defend
              <a href="https://bijleebox.com/">bijleebox.com</a> and all parties
              from whom <a href="https://bijleebox.com/">bijleebox.com</a> has
              licensed portions of Content, and their directors, officers, and
              employees, against all claims, liability, damages, costs, and
              expenses, including reasonable legal fees and expenses arising out
              of or related to (i) your breach of these Terms of Use or (ii) any
              suit, claim, or demand arising from or relating to any text,
              photograph, image, graphic, or other material you incorporated
              into Products that was not part of the standard Site Content.
            </p>

            <p>
              <strong>Disclaimer of Warranty</strong><br />
              This site is provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We
              make no representations or warranties of any kind, whether express
              or implied, as to the operation of this site or the information,
              content, materials, or products included on it. You expressly
              agree that your use of this site is at your sole risk, by your own
              free will, and that you are solely responsible for any
              consequences arising from this use. We disclaim all warranties,
              whether expressed or implied, including, but not limited to,
              implied warranties of merchantability and or fitness for a
              particular purpose.
            </p>

            <p>
              <strong>Limitation of Liability</strong><br />
              In no event shall
              <a href="https://bijleebox.com/">bijleebox.com</a> or its
              licensors, suppliers, or vendors, their officers, directors,
              employees, or agents be liable for any special, incidental,
              indirect, or consequential damages of any kind, or for any damages
              whatsoever resulting from loss of use, data, or profits, whether
              or not <a href="https://bijleebox.com/">bijleebox.com</a> has been
              advised of the possibility of damage, arising out of or in
              connection with the use or performance of the site.
            </p>

            <p>
              <strong>Error in Product Prices on Website</strong><br />
              Despite our best intentions and efforts, erroneous pricings may
              happen in a limited number of products on our website. If the
              item's correct price is lower than our published price, we shall
              invoice the lower amount, send you the item and issue Credit Note
              for the balance. However, if a product's correct price is higher
              than our published price, in that case, we will inform you with
              due supporting.
            </p>

            <p>
              <strong>Site Feedback</strong><br />
              Any comments, suggestions, proposals, or other feedback
              (collectively “Feedback Information”) provided to
              <a href="https://bijleebox.com/">bijleebox.com</a> in connection
              with the operation or content of this Site shall be provided by
              the submitter and received by
              <a href="https://bijleebox.com/">bijleebox.com</a> on a
              non-confidential basis. All such comments, suggestions, and other
              information shall become the exclusive property of
              <a href="https://bijleebox.com/">bijleebox.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  data() {
    return {
      settings: {},
    };
  },
  async mounted() {
    let settings = await axios.get("settings");
    this.settings = settings.data.data[0];
  },
};
</script>
<style scoped>
p {
  font-size: 20px;
  text-align: start;
}

.life-gambo {
  padding: 5px 0;
}
.col-lg-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 39px;
}
.title129 h2 {
  font-size: 49px;
  font-weight: 500;
  margin-top: 16px !important;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
