var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"life-gambo"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.settings.terms)}}),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-dt"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"title129"},[_c('h2',[_vm._v("Refund and Return Policy")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br'),_c('strong',[_vm._v("Returnable Products")]),_c('br'),_vm._v(" Returns must be initiated within 7 days from the date of delivery of products, subject to the other terms and conditions of this Cancellation, Return and Refund Policy. Do read all sections carefully to understand the conditions and cases under which returns will be accepted and check the Products display page for Product specific return information. (subject to Para 4.7, please keep the item in its original condition, with outer box or case, user manual, warranty cards, and other accompaniments in manufacturer packaging for a successful return pick-up) ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v(" Non-Returnable Products")]),_c('br'),_vm._v(" Notwithstanding anything to the contrary, Users cannot return certain Products which are specified as ‘non-returnable’ on the relevant Product pages of the Platforms, and (b) Such Products may be returned only in case of defects or damages in the Products, incorrect products shipped to the User, missing components and empty sealed packages, and not for any other reason. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Return of Non-Returnable Products")]),_c('br'),_vm._v(" The User can only return Non-returnable Products if there are defects and deficiencies in the Product (attributable to, and accepted by bijleebox after due verification at its sole discretion) or if such Non-returnable Products are delivered late. The User shall contact us by sending an email to "),_c('a',{attrs:{"href":"contact"}},[_vm._v(" contactus@bijleebox.com")]),_vm._v(" to initiate a request for returning such Non-returnable Products not later than 7 (seven) days from the date of delivery of Product. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v(" Return/Refund Process")]),_c('br'),_vm._v(" Subject to the terms of this Cancellation, Return and Refund Policy, bijleebox offers Users an easy return/refund policy. bijleebox also accepts partial returns wherein User can raise a return request for one or all Products except in case of Bundled Packages (as defined hereinafter). The process for initiating the process for raising a return/refund/exchange request is: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v(" Login with Your credentials and visit the "),_c('a',{attrs:{"href":"login"}},[_vm._v("‘My Account’ ")]),_vm._v("section. ")]),_c('li',[_vm._v(" If the User had used the pay on delivery mode of payment, they will be prompted to enter the customer’s bank and account details. ")]),_c('li',[_vm._v(" The Products will be picked up within the timelines intimated by bijleebox. ")]),_c('li',[_vm._v(" bijleebox will initiate the refund process only if the Products are received in accordance with the Cancellation, Return and Refund Policy. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Refund Process")]),_c('br'),_vm._v(" While raising a request for return on the Platforms, the User shall have the option to seek a refund of the monies paid by him/her towards the purchase of the Product. The User may be required to produce a copy of the original invoice at the time of placing a request for return of Products. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Bundled Packages")]),_c('br'),_vm._v(" If a User has purchased Products which form a part of a package of other Products, or if the Product forms a part of a promotional package, the User shall be required to return all the Products that form a part of the Bundled Package for bijleebox to process refunds. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v(" Conditions for Rejecting Returns")]),_c('br'),_vm._v(" Return of or refund for purchased Products shall not be entertained by bijleebox if: ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',[_c('li',[_vm._v("The Product has been used.")]),_c('li',[_vm._v(" The price tags, brand tags, box, original packaging material, and accompanying accessories have been damaged or discarded by the User. ")]),_c('li',[_vm._v(" The serial number / bar code of the Product does not match bijleebox records. ")]),_c('li',[_vm._v(" Accessories delivered with the Product are not returned in an undamaged condition. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Grievance Redressal")]),_c('br'),_vm._v(" Any grievances relating to the Cancellation, Return and Refund Policy may be directed by You to the grievance officer of bijleebox who can be contacted at "),_c('a',{attrs:{"href":"contact"}},[_vm._v("contactus@bijleebox.com.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Note:")]),_c('br'),_vm._v(" Please ensure that the Product(s) being returned comply with the conditions set out under this Cancellation, Return and Refund Policy and are, among other conditions, unused with no damage and stains, with price label intact, all packaging material undamaged and unused. ")])
}]

export { render, staticRenderFns }