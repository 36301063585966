<template>
  <section>
    <!-- Body Start -->
    <div class="wrapper">
      <div class="default-dt">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="title129">
                <h2>Privacy Policy</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="life-gambo">
        <div class="container">
          <div class="row">
            <p>
              <br /><strong>privacy policy of bijleebox:</strong><br />
              Your privacy is important to us. This Privacy Policy explains how
              we collect, use, and protect your personal information when you
              visit and use our website.
            </p>

            <p>
              <strong>Information We Collect</strong><br />
              We collect personal information such as your name, email address,
              and phone number when you register on our website or make a
              purchase. We may also collect non-personal information such as
              your IP address, browser type, and referring URL.
            </p>

            <p>
              <strong>Use of Information</strong><br />
              We use the information we collect to improve our services, process
              orders, respond to your inquiries, and communicate promotions or
              offers that may interest you. We may also use your information to
              personalize your experience on our website.
            </p>

            <p>
              <strong>Cookies and Tracking Technologies</strong><br />
              Our website uses cookies to enhance your experience and track
              usage patterns. Cookies are small files that are stored on your
              device and help us improve website functionality. You can disable
              cookies through your browser settings, but this may affect your
              ability to use certain features on our website.
            </p>

            <p>
              <strong>Data Sharing and Disclosure</strong><br />
              We do not sell or rent your personal information to third parties.
              However, we may share your information with trusted partners who
              assist us in operating our website, conducting business, or
              servicing you. We may also share information when required by law
              or to protect our rights.
            </p>

            <p>
              <strong>Security</strong><br />
              We implement appropriate security measures to protect your
              personal information from unauthorized access, disclosure, or
              alteration. While we strive to protect your data, no method of
              transmission over the internet is completely secure, and we cannot
              guarantee the absolute security of your information.
            </p>

            <p>
              <strong>Retention of Information</strong><br />
              We retain your personal information only for as long as necessary
              to fulfill the purposes for which it was collected, comply with
              legal obligations, and resolve disputes.
            </p>

            <p>
              <strong>Your Rights</strong><br />
              You have the right to access, update, or delete the personal
              information we hold about you. If you wish to exercise any of
              these rights, please contact us using the details provided below.
            </p>

            <p>
              <strong>Third-Party Links</strong><br />
              Our website may contain links to third-party websites. We are not
              responsible for the content or privacy practices of these
              websites, and we encourage you to review their privacy policies
              before providing any personal information.
            </p>

            <p>
              <strong>Changes to Privacy Policy</strong><br />
              We may update this Privacy Policy from time to time. We will post
              any changes to the policy on this page and update the effective
              date at the bottom of the policy.
            </p>

            <p>
              <strong>Contact Us</strong><br />
              If you have any questions or concerns about this Privacy Policy or
              the way we handle your personal information, please contact us at:
              <a href="contact">support@bijleebox.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Body End -->
  </section>
</template>

<script type="text/javascript">
import axios from "axios";

export default {
  data() {
    return {
      settings: {},
    };
  },
  async mounted() {
    let settings = await axios.get("settings");
    this.settings = settings.data.data[0];
  },
};
</script>
<style scoped>
p {
  font-size: 16px;
  text-align: start;
}

.life-gambo {
  padding: 5px 0;
}
.col-lg-12 {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 39px;
}
.title129 h2 {
  font-size: 49px;
  font-weight: 700;
  margin-top: 16px !important;
  color: white;
  text-align: center;
  line-height: 30px;
}
</style>
