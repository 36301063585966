<template>
  <section>
    <category-modal />

    <!-- Header Start -->
    <header class="header clearfix">
      <div class="top-header-group">
        <div class="top-header">
          <div class="res_main_logo">
            <router-link to="/"
              ><img src="/images/bb_logo.png" class="logo-size" alt=""
            /></router-link>
          </div>
          <div class="main_logo" id="logo">
            <router-link to="/"
              ><img src="/images/bb_logo.png" class="logo-size" alt=""
            /></router-link>
            <router-link to="/"
              ><img
                class="logo-inverse logo-size"
                src="/images/bb_logo.png"
                alt=""
            /></router-link>
          </div>
          <div class="select_location">
            <div class="ui inline dropdown loc-title">
              <div class="text">
                <i class="uil uil-location-point"></i>
                {{ city.name || "Select City" }}
              </div>
              <i class="uil uil-angle-down icon__14"></i>
              <div class="menu dropdown_loc">
                <div
                  class="item channel_item"
                  v-for="(city, c) in cities"
                  :key="`city${c}`">
                  <i class="uil uil-location-point"></i>
                  <span @click="setcity(city)">{{ city.name }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="search120">
            <div class="ui search">
              <div class="ui left icon input swdh10">
                <input
                  class="prompt srch10"
                  type="text"
                  placeholder="Search for products.."
                  v-model="search_keyword"
                  @keydown.enter="updateSearch" />
                <i class="uil uil-search-alt icon icon1"></i>
              </div>
            </div>
          </div>
          <div class="header_right">
            <ul>
              <li>
                <a href="#" class="offer-link"
                  ><i class="uil uil-phone-alt"></i
                  >{{ setting ? setting.phone : "" }}</a
                >
              </li>
              <li>
                <router-link to="/offers" class="offer-link"
                  ><i class="uil uil-gift"></i>Offers</router-link
                >
              </li>
              <li>
                <router-link to="/faqs" class="offer-link"
                  ><i class="uil uil-question-circle"></i>Help</router-link
                >
              </li>
              <!-- <li>
                <a href="dashboard_my_wishlist.html" class="option_links" title="Wishlist"><i class='uil uil-heart icon_wishlist'></i><span class="noti_count1">3</span></a>
              </li>  -->
              <li class="ui dropdown" v-if="authenticated">
                <a href="#" class="opts_account">
                  <b-avatar
                    variant="dark"
                    :text="
                      user.first_name ? user.first_name.charAt(0) : ''
                    "></b-avatar>
                  <span class="user__name">{{ user.first_name }}</span>
                  <i class="uil uil-angle-down"></i>
                </a>
                <div class="menu dropdown_account">
                  <!-- <router-link to="/user/dashboard" class="item channel_item"><i class="uil uil-apps icon__1"></i>Dashbaord</router-link>  -->
                  <router-link to="/user/orders" class="item channel_item"
                    ><i class="uil uil-box icon__1"></i>My Orders</router-link
                  >
                  <router-link to="/user/wallet" class="item channel_item"
                    ><i class="uil uil-usd-circle icon__1"></i>My
                    Wallet</router-link
                  >
                  <router-link to="/user/address" class="item channel_item"
                    ><i class="uil uil-location-point icon__1"></i>My
                    Address</router-link
                  >
                  <router-link to="/offers" class="item channel_item"
                    ><i class="uil uil-gift icon__1"></i>Offers</router-link
                  >
                  <a href="#" @click.prevent="logout" class="item channel_item"
                    ><i class="uil uil-lock-alt icon__1"></i>Logout</a
                  >
                </div>
              </li>
              <li v-else class="ui dropdown">
                <a href="#" class="opts_account">
                  <img src="images/avatar/img-21.jpg" alt="" />
                  <span class="user__name">Register or Sign in</span>
                  <i class="uil uil-angle-down"></i>
                </a>
                <div
                  class="menu right dropdown_account dropdown_account_rl"
                  style="right: 0; left: auto">
                  <div class="rl-account">
                    <div class="rl-account-step">
                      <p>Returning Customer?</p>
                      <router-link
                        to="/login"
                        class="item lr-acount-btn hover-btn">
                        Login
                      </router-link>
                    </div>
                    <div class="rl-account-step">
                      <p>Don't have an account ?</p>
                      <router-link
                        to="/register"
                        class="item lr-acount-btn hover-btn">
                        Register
                      </router-link>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sub-header-group">
        <div class="sub-header">
          <div class="ui dropdown" style="visibility: hidden">
            <a
              href="#"
              class="category_drop hover-btn"
              data-toggle="modal"
              data-target="#category_model"
              title="Categories"
              ><i class="uil uil-apps"></i
              ><span class="cate__icon">Select Category</span></a
            >
          </div>
          <nav class="navbar navbar-expand-lg navbar-light py-3">
            <div class="container-fluid">
              <button
                class="navbar-toggler menu_toggle_btn"
                type="button"
                data-target="#navbarSupportedContent">
                <i class="uil uil-bars"></i>
              </button>
              <div
                class="collapse navbar-collapse d-flex flex-column flex-lg-row flex-xl-row justify-content-lg-end bg-dark1 p-3 p-lg-0 mt1-5 mt-lg-0 mobileMenu"
                id="navbarSupportedContent">
                <ul class="navbar-nav main_nav align-self-stretch">
                  <li class="nav-item">
                    <router-link to="/" class="nav-link active" title="Home"
                      >Home</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <div
                      class="nav-link-container"
                      @mouseenter="showHoverCategories"
                      @mouseleave="hideHoverCategories">
                      <router-link to="/" class="nav-link e-store-class">
                        E-store
                      </router-link>
                      <hover-categories
                        v-if="isHoverCategoriesVisible"
                        class="hover-categories"
                        @mouseenter="showHoverCategories"
                        @mouseleave="hideHoverCategories" />
                    </div>
                  </li>
                  <li class="nav-item">
                    <router-link to="/about" class="nav-link" title="About"
                      >About Us</router-link
                    >
                  </li>
                  <li class="nav-item">
                    <router-link to="/contact" class="nav-link" title="Contact"
                      >Contact Us</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div class="catey__icon">
            <a
              href="#"
              class="cate__btn"
              data-toggle="modal"
              data-target="#category_model"
              title="Categories"
              ><i class="uil uil-apps"></i
            ></a>
          </div>
          <div class="header_cart order-1">
            <a
              href="#"
              class="cart__btn hover-btn pull-bs-canvas-left"
              title="Cart"
              style="font-size: 18px"
              ><i class="uil uil-shopping-cart-alt"></i
              ><span
                >Cart<sup
                  >&nbsp;&nbsp;<b>{{ order.order_details.length }}</b></sup
                ></span
              ><i class="uil uil-angle-down"></i
            ></a>
          </div>
          <div class="search__icon order-1">
            <a
              href="#"
              class="search__btn hover-btn"
              data-toggle="modal"
              data-target="#search_model"
              title="Search"
              ><i style="font-size: 18px !important" class="uil uil-search"></i
            ></a>
          </div>
        </div>
      </div>
    </header>
    <!-- Header End -->
  </section>
</template>

<script type="text/javascript">
import CategoryModal from "@/views/layout/category-modal";
import { mapGetters, mapActions } from "vuex";
import HoverCategories from "@/views/layout/categories-hover.vue";
import axios from "axios";

export default {
  data: () => ({
    setting: {},
    search_keyword: "",
    isHoverCategoriesVisible: false,
  }),
  components: {
    CategoryModal,
    HoverCategories,
  },
  computed: {
    ...mapGetters({
      cities: "city/getCities",
      authenticated: "auth/authenticated",
      user: "auth/user",
      order: "order/getOrder",
      city: "city/getCity",
    }),
  },
  watch: {
    $route(to) {
      console.log("called header router", to);

      if (to.query.keyword) {
        this.search_keyword = to.query.keyword;
      } else {
        this.search_keyword = "";
      }
    },
  },
  mounted() {
    this.getSetting();
    console.log("card order", this.order);
    console.log("order length", this.order.order_details.length);
  },
  methods: {
    ...mapActions({
      logOut: "auth/logOut",
    }),
    logout() {
      this.logOut();
      this.$router.replace("/");
    },
    setcity(city) {
      this.$store.dispatch("city/setCity", city);
      this.$router.go({ path: "/" });
    },
    async getSetting() {
      let setting = await axios.get("/settings/1");
      this.setting = setting.data.data;
    },
    updateSearch() {
      console.log(this.search_keyword);
      this.$emit("search", this.search_keyword);
    },
    showHoverCategories() {
      this.isHoverCategoriesVisible = true;
    },
    hideHoverCategories() {
      this.isHoverCategoriesVisible = false;
    },
  },
};
</script>
<style scoped>
.nav-link-container {
  position: relative;
}

.hover-categories {
  display: block;
  /* Always show when isHoverCategoriesVisible is true */
  position: absolute;
  top: 100%;
  left: -185px;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.hover-categories.hidden {
  display: none;
  /* Hide dropdown */
}
</style>
<style>
.logo-size {
  width: 130px !important;
  height: 50px;
}

.top-header {
  background-color: #f5c209;
}
</style>
