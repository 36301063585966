<template>
  <section>
    <footer class="footer">
      <div class="footer-first-row">
        <div class="container">
          <div class="row">
            <div class="col-md-6 col-sm-6">
              <ul class="call-email-alt">
                <li>
                  <a href="#" class="callemail"
                    ><i class="uil uil-dialpad-alt"></i>{{ setting.phone }}</a
                  >
                </li>
                <li>
                  <a href="#" class="callemail"
                    ><i class="uil uil-envelope-alt"></i>{{ setting.email }}</a
                  >
                </li>
              </ul>
            </div>
            <div class="col-md-6 col-sm-6">
              <div class="social-links-footer">
                <ul>
                  <li>
                    <a :href="setting.fb_path" target="_blank"
                      ><i class="fab fa-facebook-f"></i
                    ></a>
                  </li>
                  <li>
                    <a :href="setting.twitter_path" target="_blank"
                      ><i class="fab fa-twitter"></i
                    ></a>
                  </li>
                  <li>
                    <a :href="setting.linkedin_path" target="_blank"
                      ><i class="fab fa-linkedin-in"></i
                    ></a>
                  </li>
                  <li>
                    <a :href="setting.instagram_path" target="_blank"
                      ><i class="fab fa-instagram"></i
                    ></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-second-row">
        <div class="container">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="second-row-item">
                <h4>Categories</h4>
                <ul>
                  <li v-for="(category, c) in categories" :key="`category${c}`">
                    <router-link :to="`/categories/${category.id}`">
                      {{ category.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="second-row-item">
                <h4>Useful Links</h4>
                <ul>
                  <li><router-link to="/" title="Home">Home</router-link></li>
                  <li>
                    <router-link to="/about" title="About"
                      >About Us</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/contact" title="Contact"
                      >Contact Us</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/terms" title="terms"
                      >Terms & Conditions</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/privacy" title="terms"
                      >privacy policy</router-link
                    >
                  </li>
                  <li>
                    <router-link to="/return" title="terms"
                      >Refund & Return Policy</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="second-row-item">
                <h4>Top Cities</h4>
                <ul>
                  <li v-for="(city, c) in cities" :key="`city${c}`">
                    <router-link to="/">
                      {{ city.name }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
              <div class="second-row-item-app">
                <h4>Download App</h4>
                <ul>
                  <li>
                    <a href="#"
                      ><img
                        class="download-btn"
                        src="/images/download-1.svg"
                        alt=""
                    /></a>
                  </li>
                  <li>
                    <a href="#"
                      ><img
                        class="download-btn"
                        src="/images/download-2.svg"
                        alt=""
                    /></a>
                  </li>
                </ul>
              </div>
              <div class="second-row-item-payment">
                <h4>Payment Method</h4>
                <div class="footer-payments">
                  <ul id="paypal-gateway" class="financial-institutes">
                    <li class="financial-institutes__logo">
                      <img
                        alt="Visa"
                        title="Visa"
                        src="/images/footer-icons/pyicon-6.svg" />
                    </li>
                    <li class="financial-institutes__logo">
                      <img
                        alt="Visa"
                        title="Visa"
                        src="/images/footer-icons/pyicon-1.svg" />
                    </li>
                    <li class="financial-institutes__logo">
                      <img
                        alt="MasterCard"
                        title="MasterCard"
                        src="/images/footer-icons/pyicon-2.svg" />
                    </li>
                    <li class="financial-institutes__logo">
                      <img
                        alt="American Express"
                        title="American Express"
                        src="/images/footer-icons/pyicon-3.svg" />
                    </li>
                    <li class="financial-institutes__logo">
                      <img
                        alt="Discover"
                        title="Discover"
                        src="/images/footer-icons/pyicon-4.svg" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-last-row">
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <div class="footer-bottom-links">
                <ul>
                  <li><a href="/about">About</a></li>
                  <li><a href="/contact">Contact</a></li>
                  <li><a href="/privacy">Privacy Policy</a></li>
                  <li><a href="/terms">Term & Conditions</a></li>
                  <li><a href="/return">Refund & Return Policy</a></li>
                  <!-- <li><a href="/disclaimer">Disclaimer</a></li> -->
                </ul>
              </div>
              <div class="copyright-text">
                <i class="uil uil-copyright"></i>Copyright 2024
                <b>Bijlee Box</b> . All rights reserved
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer End -->
  </section>
</template>

<script type="text/javascript">
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    setting: {},
  }),
  computed: {
    ...mapGetters({
      categories: "category/getCategories",
      cities: "city/getCities",
    }),
  },
  mounted() {
    this.getSetting();
  },
  methods: {
    async getSetting() {
      let setting = await axios.get("/settings/1");
      this.setting = setting.data.data;
    },
  },
};
</script>
