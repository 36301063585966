var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"wrapper"},[_vm._m(0),_c('div',{staticClass:"life-gambo"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.settings.terms)}}),_vm._m(1),_c('p',[_vm._v(" You also agree that we may provide all legal communications and notices to you electronically by posting them on our website or, at our election, by sending an e-mail to the e-mail address you provided to us when you registered at our website. You may withdraw your consent to receive communications from us electronically by contacting customer care. However, if you withdraw your consent to receive our communications electronically, you must discontinue your use of your account. ")]),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"default-dt"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',{staticClass:"title129"},[_c('h2',[_vm._v("Terms and Conditions")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Acceptance of Terms of Use")]),_c('br'),_vm._v(" Please read these Terms of Use carefully. These Terms of Use govern your access and use of this Site. By accessing or using this Site you agree to be bound by these Terms of Use and to any additional guidelines, restrictions, or rules that may be posted in connection with specific sections or services of this Site. All such additional posted guidelines, restrictions, or rules are hereby incorporated by reference into these Terms of Use. "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" reserves the right to make changes to this Site and to these Terms of Use at any time without prior notice. You should review these Terms of Use each time you access this Site. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Copyrighted Materials for Limited Use")]),_c('br'),_vm._v(" This Site contains graphics, photographs, images, document layouts, text, fonts, software tools, and other information (referred to here in as “Content”). This Site and all Content are the copyrighted property of "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" and its subsidiaries (referred to herein as “"),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v("”) or the copyrighted property of parties from whom "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" has licensed such property. All rights in this Site and its Content are reserved worldwide. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Use of Site")]),_c('br'),_vm._v(" You are granted permission to access and use this Site and its Content for the sole purpose of evaluating, and ordering products solely through "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(". No other download, retention, use, publication, or distribution of any portion of the Content is authorized or permitted. Obtaining Products from "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" does not entitle you to use any portion of Content apart from the finished Products as they are supplied by "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(". ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Indemnification")]),_c('br'),_vm._v(" You agree that you shall indemnify and defend "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" and all parties from whom "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" has licensed portions of Content, and their directors, officers, and employees, against all claims, liability, damages, costs, and expenses, including reasonable legal fees and expenses arising out of or related to (i) your breach of these Terms of Use or (ii) any suit, claim, or demand arising from or relating to any text, photograph, image, graphic, or other material you incorporated into Products that was not part of the standard Site Content. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Disclaimer of Warranty")]),_c('br'),_vm._v(" This site is provided on an ‘AS IS’ and ‘AS AVAILABLE’ basis. We make no representations or warranties of any kind, whether express or implied, as to the operation of this site or the information, content, materials, or products included on it. You expressly agree that your use of this site is at your sole risk, by your own free will, and that you are solely responsible for any consequences arising from this use. We disclaim all warranties, whether expressed or implied, including, but not limited to, implied warranties of merchantability and or fitness for a particular purpose. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Limitation of Liability")]),_c('br'),_vm._v(" In no event shall "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" or its licensors, suppliers, or vendors, their officers, directors, employees, or agents be liable for any special, incidental, indirect, or consequential damages of any kind, or for any damages whatsoever resulting from loss of use, data, or profits, whether or not "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" has been advised of the possibility of damage, arising out of or in connection with the use or performance of the site. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Error in Product Prices on Website")]),_c('br'),_vm._v(" Despite our best intentions and efforts, erroneous pricings may happen in a limited number of products on our website. If the item's correct price is lower than our published price, we shall invoice the lower amount, send you the item and issue Credit Note for the balance. However, if a product's correct price is higher than our published price, in that case, we will inform you with due supporting. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Site Feedback")]),_c('br'),_vm._v(" Any comments, suggestions, proposals, or other feedback (collectively “Feedback Information”) provided to "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" in connection with the operation or content of this Site shall be provided by the submitter and received by "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(" on a non-confidential basis. All such comments, suggestions, and other information shall become the exclusive property of "),_c('a',{attrs:{"href":"https://bijleebox.com/"}},[_vm._v("bijleebox.com")]),_vm._v(". ")])
}]

export { render, staticRenderFns }